@import "../../../../branding/app-defenitions";

// переопределяется в Tele2
#passage-text.passage {
  transform: scale(0.75) translate(0, 50%);
}

.player-finish {
  .score {
    font-size: 1.2em
  }

  .upload {
    font-size: 0.8em;
  }

  /*.btn-primary.btn {
    padding: 8px 0 !important;
  }*/

  @media @desktop {
    & {
      .block-first {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;

        .art-play-finish {
          margin-left: 0;
          margin-right: 0;
        }

        .score, .upload {
          text-align: left;
          margin-left: 32px;
        }
      }
    }
  }

  .art-play-finish {
    border-radius: 50%;
    margin-top: 16px;
    border: 16px white solid;
    box-sizing: content-box;
    overflow: hidden;

    .score {
      margin-left: 0;
    }
  }
}

.page-playback {
  @media @desktop {
    & {
      .karplayer {
        height: auto;
        min-height: auto;
        position: relative;

        .wrapper {
          overflow: hidden;
        }
      }

      .siri-container {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        //bottom: 0;
        bottom: 20%;
        overflow: hidden;
      }

      player-song-copyrights {
        display: inline-block;
        margin-top: 28px;
        margin-left: 18px + @desktop-offset;
      }

      player-song-progress {
        .progress {
          margin: 0;
          height: 10px;
          border-radius: 0;
        }
      }

      .karplayer > .control-block {
        margin: 0 @desktop-offset;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        position: relative;
        margin-top: 240px;

        player-song-title {
          margin-right: 64px;
        }

        .play-pause {
          position: absolute;
          left: 0;
          bottom: 3px;

          width: 54px;
          margin: 5px;
        }
      }

      .share {
        display: block;
        text-align: center;
        padding: 5px 0;
        margin-top: 0;

        span {
          line-height: 32px;
          margin: 0 16px;
        }

        &:after {
          content: '';
          display: block;
          clear: both;
        }
      }

      .sing-label, .download-label {
        width: 64px;
        //margin: 0 12px 0 64px;
        margin: 0 22px 0 42px;
        color: white;
      }


      .sing, .download, .ringtone {

        a {
          height: 64px;
          min-width: 64px;
        }
      }

      .media.control-block {
        margin: 0;
        padding: 0;
        white-space: nowrap;

        .cover {
          border-radius: 0;
          width: 64px;
          height: 64px;
        }

        .media-body {
          width: auto;

          .artist {
            margin-top: 10px;

            line-height: 1.5em;
          }

          .song {
            line-height: 1.5em;
          }
        }
      }

      .rzslider .rz-pointer {
        background-color: white;
        border: 8px solid black;
      }

    }
  }
}

.share, .player-buttons {
  margin: 1em auto;

  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
}

.karplayer {
  
  &.stage-countdown-3sec .kar-loader {
    opacity: 1;
    z-index: 1;
    .screen {
      animation-name: karplayer-component-countdown;
    }
  }

  @keyframes karplayer-component-countdown {
    0% {
      opacity: 0;
      transform: translateY(-50%) scale(0.5, 0.5);
    }
    1% {
      opacity: 1;
      transform: translateY(-50%) scale(0.5, 0.5);
    }
    100% {
      //color: red;
      opacity: 0;
      transform: translateY(-50%) scale(3.0, 3.0);
    }
  }
}

