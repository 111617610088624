@import "../../../karaoke/common/media";

promo {
  display: block;

  .watch-all {
    text-align: center;
    text-decoration: underline;
    margin: 12px auto;
    display: block;
    width: auto;
  }

  @media @desktop {
    & {
      .app-header-line .arrow {
        display: none;
      }
      .songs {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: 0 2vw;
      }
    }
  }

  @media @phone {
    .songs, .covers, .watch-all {
      display: none;
    }
  }
}

