songs-list {
  display: block;
  clear: both;

  @media (min-width: 992px) {
    .grid-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-gap: 0 2vw;
    }
  }
}