@import "../../common/media";

.disclaimer-desktop(@karaoke-primary-accent-color, @karaoke-t2w-color-black, @karaoke-t2w-color-white, @desktop-offset) {
/*
  background-color: @karaoke-primary-accent-color;

  .app-label:first-child {
    font-weight: bold;
  }

  .app-label {
    float: left;
    margin: 4px 0;
    background-color: transparent;
    font-size: 16px;
    text-align: left;
    padding-left: @desktop-offset;
    padding-right: 4px;
  }

  .button-subscribe {
    background-color: transparent;
    border-color: @karaoke-t2w-color-black;
    color: @karaoke-t2w-color-black;

    &:after {
      color: @karaoke-t2w-color-black;
    }

    &:hover:after {
      color: @karaoke-t2w-color-white;
    }
  }

  .center-block {
    float: right;
    margin: 6px @desktop-offset 6px 0;
  }

  ::after {
    content: '';
    display: block;
    clear: both;
    width: 100%;
    height: 0;
  }*/

}

.disclaimer {
  .app-label {
    display: block;
    float: none;
  }
}
