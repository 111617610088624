@import "../../../../branding/tcell/app-tcell-defenition";
@import "./PageTitleComponent_tpl_v2_legacy";

.page-title :global {

  background: @karaoke-tcell-light-purple;

  font-style: italic;

  .auth-button {
    display: none;
  }

  .search-button {
    display: none;
  }

  .profile-button {
    display: none;
  }

  //.app-header {
    //background: rgba(0, 0, 0, 0.75);
    //background: rgba(0, 136, 79, 0.75);

    //margin-bottom: 130px;
    //margin-bottom: 95px;
    /*

    .logo {
      //content: url('./images/title.png');
      .image-content-full-branding('./images/title.png');
      max-height: none;
    }

    .link-wrapper a.logo {
      padding: 0;
      margin: 0;
      top: 0;
      left: 0;
      transform: initial;
      -webkit-transform: initial;
    }

    .link-wrapper a:hover.logo {
      //content: url('./images/title_active.png');
      .image-content-full-branding('./images/title_active.png');
      border: none;
      box-shadow: none;
    }*/

    .link-wrapper {
      margin: 0 40px;
      position: relative;
      height: 100%;
      text-align: center;
      
    }

                              //&:before, &:after {
                              //  display: block;
                              //  z-index: -1;
                              //  position: absolute;
                              //  //width: 100%;
                              //  height: 180px;
                              //
                              //  top: 0;
                              //  left: 0;
                              //  right: 0;
                              //}
                              //
                              ////&:before {
                              ////  content: ' ';
                              ////  background-image: url('./images/title_bg_circle.png');
                              ////  background-repeat: no-repeat;
                              ////  background-size: auto auto;
                              ////  background-position: top left;
                              ////}
                              //
                              //&:after {
                              //  content: ' ';
                              //  top: 64px;
                              //  background-image: none;
                              //  background-repeat: no-repeat;
                              //  background-size: 110px auto;
                              //  background-position: top right;
                              //}
                              //
                              //&.notPaid:after {
                              //  content: ' ';
                              //  top: 64px;
                              //  background-image: none;
                              //  background-repeat: no-repeat;
                              //  background-size: 110px auto;
                              //  background-position: top right;
                              //}

    .menu-button {
      a {
        //content: url('./images/ico_menu.png');
        //.image-content-branding('./images/ico_menu.png');
        background-image: url('./tcell/ico_menu.png');
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    /*.profile-button {
      display: none;
    }*/

    .logo {
      background: none;
      //left: 65px;
      text-align: center;
      margin-left: 5px !important;

      &:before {
        content: "КАРАОКЕ";
        display: inline-block;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: bold;
        font-size: 20px;
        color: #ffffff;
        letter-spacing: 0;
        line-height: 35px;
        vertical-align: middle;
        font-style: normal;
      }
    }
  //}


  @media @desktop {

    color: @karaoke-t2w-color-white;

    & {
      margin: 0 64px 0 40px;
      
      .link-wrapper {
        //margin: 0;
        margin: -2px 12px 0 40px;
      }

      .profile-button {
        a {
          //.image-content-branding('./images/ico_profile_desktop.png');
          background-image: url('./tcell/ico_profile_desktop.png');
          background-repeat: no-repeat;
          background-position: center center;
        }
        .text {
          color: @karaoke-t2w-color-gray;
        }
      }
    }


    & {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .search-button {
      right: 90px;
      padding-right: 40px;
      a {
        //.image-content-branding('./images/ico_header_search.png') !important;
        background-image: url('./tcell/ico_header_search.png');
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    .auth-button {
      a {
        //.image-content-branding('./images/ico_auth.png')!important;
        background-image: url('./tcell/ico_auth.png');
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  // mobile
  @media @phone {

    @section-new-bg-color: @karaoke-tcell-blue !important;

    & {
      .logo, .link-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border-radius: 16px;
        padding: 3px 6px;
        margin: 0;

      }
    }
  }

  .submenu {
    font-size: 14px;
    text-align: left;
    margin-left: 160px;
    margin-right: 160px;

    a {
      background-color: @karaoke-tcell-light-purple !important;
    }

    & > * {
      margin-left: 16px;
      /*&:first-child {
        margin-left: 0;
      }*/
    }
  }

  & {
    .text {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: @karaoke-tcell-color-white;
    }

    .button {
      .text {
        color: @karaoke-tcell-color-white;
        font-weight: lighter;
        font-style: normal;
      }
    }
  }
}
