@import "../../../../branding/app-defenitions";

//noinspection CssInvalidHtmlTagReference
.player-song-progress {
  display: block;

  .progress {
    height: 5px;
    background: #ffffff;
    margin-bottom: 5px;
  }

  .progress-begin {
    float: left;
  }

  .progress-end {
    float: right;
  }

  .progress {
    margin-bottom: 5px;
    height: 4px;
    border-radius: 10px;
  }

  &:after {
    display: block;
    content: '';
    clear: both;
  }

  .progress {
    position: relative;

    .progress-bar {
      .transition(width 0.05s linear);
    }
  }

  .rewinder {
    display: none;
    background: black;
  }

  &.rewinderable {
    .progress {
      cursor: pointer;
      overflow: hidden;
    }

    .rewinder {
      display: block;
      position: absolute;
      width: 3px;

      top: 0;
      bottom: 0;
      height: 100%;
      z-index: 1;
      left: -3px;
    }
  }
}