@import "../../../../branding/tcell/app-tcell-defenition";
@import "KarplayerComponent_tpl_v2";

.karplayer :global {

  .karplayer-impl();

  .karaoke-tcell-font-regular();

  .player-song-copyright > * {
    color:@karaoke-tcell-color-white;
  }

  .player-content {
    .karaoke-tcell-font-regular();
    .karplayer .kar-progress .kar-progress-begin, .karplayer .kar-progress .kar-progress-end {
      .karaoke-tcell-font-small();
    }
  }

  .kar-text {
    color: @karaoke-tcell-color-white;

    // загрузили - приводим в начальное состояние
    &.ready {
      // пропеваемый блок
      .karaokeBlock.speakingBlock {
        span[data-begin].speaking {
          color: @karaoke-tcell-color-violet-dark;
        }

        span[data-begin].speaking.finish {
          color: @karaoke-tcell-color-violet-dark;
        }
      }
    }
  }

  .kar-progress {
    .progress {
      background: @karaoke-tcell-color-violet-dark;

      .kar-progress-bar.progress-bar {
        background-color: @karaoke-tcell-color-white;
      }
    }

    .kar-progress-begin, .kar-progress-end {
      .karaoke-tcell-font-regular();
      color: @karaoke-tcell-color-white;
    }
  }

  .control-block.mobile {
    color: @karaoke-tcell-color-white;

    .category {
      .karaoke-tcell-font-small();
      color: @karaoke-tcell-color-dark;
    }

    .artist, .song {
      color: @karaoke-tcell-color-dark;
    }

    .artist {
      .karaoke-tcell-font-large();

      &:after {
        .karaoke-tcell-font-large();
      }
    }

    .kar-button-main {
      .btn-primary {
        background-color: transparent;
      }

      width: image-width("./tcell/player_next.png");
      height: image-height("./tcell/player_next.png");

      .stop {
        background-image: url("./tcell/player_play.png");
      }

      .pause {
        background-image: url("./tcell/player_play.png");
      }

      .play {
        background-image: url("./tcell/player_stop.png");
      }

      .load {
        background-image: url("./tcell/player_wait.png");
      }
    }

    .play-next.btn, .play-next.btn:hover {
      width: image-width("./tcell/player_play.png");
      height: image-height("./tcell/player_play.png");
      background-image: url("./tcell/player_next.png");
    }

    .play-next.play-end, a.play-next.play-end:hover {
      background-image: url("./tcell/player_finish.png");
    }
  }


  .control-block.desktop {
    .media-body {
      .artist {
        .karaoke-tcell-font-regular();
        color: @karaoke-search-placeholder-color;
      }

      .song {
        .karaoke-tcell-font-regular();
        color: @karaoke-tcell-color-white;
      }
    }

    .play-end .pb-icon {
      background-image: url('./tcell/player_finish.png');
    }

    .play-next .pb-icon {
      background-image: url('./tcell/player_next.png');
    }
  }

  @media @desktop {
    .wrapper {
      background: @karaoke-tcell-color-dark;
    }

    player-song-copyrights {
      color: @karaoke-tcell-color-white;
    }

    .kar-text {
      color: @karaoke-tcell-color-white;
    }
  }

  player-button {
    .pb-icon {
      &.state-stop {
        background-image: url("./tcell/player_play.png");
      }

      &.state-pause {
        background-image: url("./tcell/player_play.png");
      }

      &.state-play {
        background-image: url("./tcell/player_stop.png");
      }

      &.state-load {
        background-image: url("./tcell/player_wait.png");
        animation: fa-spin 2s infinite linear;
      }
    }

    .pb-label {
      .karaoke-tcell-font-small();
      color: @karaoke-tcell-color-white;
    }
  }

  player-button.sing .pb-icon {
    background-image: url("./tcell/player_sing.png");
  }

  player-button.record {
    .pb-icon {
      &.state-disabled {
        background-image: url("./tcell/recorder_disabled.png");
      }

      &.state-off {
        background-image: url("./tcell/recorder_disabled.png");
      }

      &.state-on {
        background-image: url("./tcell/recorder_enabled.png");
      }

      &.state-duet {
        background-image: url("./tcell/recorder_duet_enabled.png");
      }

      &.state-battle {
        background-image: url("./tcell/recorder_battle_enabled.png");
      }
    }
  }

  @media @phone {
    player-song-title {
      display: block;
      margin-left: @karaoke-dim-page-padding-mobile;
      margin-right: @karaoke-dim-page-padding-mobile;
    }

    .kar-progress {
      margin-left: @karaoke-dim-page-padding-mobile;
      margin-right: @karaoke-dim-page-padding-mobile;
    }
  }

  .player-finish {
    .btn-primary.btn {
      padding: 8px 0 !important;
    }
  }
}
