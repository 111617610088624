@import "../../../../branding/tcell/app-tcell-defenition";
@import "MenuComponent";

.menu :global {

  .search {
    margin: 15px 20px;
  }
  
  .app-header .back-button {
    a {
      background-image: url("./tcell/ico_close.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }


  .app-header-line {
    .open {
      //content: data-uri('./images/ico_arrow_down.png');
      //.image-content('./images/ico_arrow_down.png');
      background-image: data-uri('./common/ico_arrow_down.png');
      //background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .close {
      //content: data-uri('./images/ico_arrow_up.png');
      //.image-content('./images/ico_arrow_up.png');
      background-image: data-uri('./common/ico_arrow_up.png');
      //background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  /*& {
    .section-main { .app-header-line-menu-color(@section-main-bg-color, @section-main-border-color, @section-main-hover-bg-color, @section-main-hover-border-color); }
    .section-catalog { .app-header-line-menu-color(@section-catalog-bg-color, @section-catalog-border-color, @section-catalog-hover-bg-color, @section-catalog-hover-border-color); }
    .section-popular { .app-header-line-menu-color(@section-popular-bg-color, @section-popular-border-color, @section-popular-hover-bg-color, @section-popular-hover-border-color); }
    .section-playlist { .app-header-line-menu-color(@section-playlist-bg-color, @section-playlist-border-color, @section-playlist-hover-bg-color, @section-playlist-hover-border-color); }
    .section-new { .app-header-line-menu-color(@section-new-bg-color, @section-new-border-color, @section-new-hover-bg-color, @section-new-hover-border-color); }
    .section-feedback { .app-header-line-menu-color(@section-playlist-bg-color, @section-playlist-border-color, @section-playlist-hover-bg-color, @section-playlist-hover-border-color); }

    .app-header-line {
      &.section-main { .app-header-line-color(@section-main-bg-color, @section-main-border-color, @section-main-hover-bg-color, @section-main-hover-border-color); }
      &.section-catalog { .app-header-line-color(@section-catalog-bg-color, @section-catalog-border-color, @section-catalog-hover-bg-color, @section-catalog-hover-border-color); }
      &.section-popular { .app-header-line-color(@section-popular-bg-color, @section-popular-border-color, @section-popular-hover-bg-color, @section-popular-hover-border-color); }
      &.section-playlist { .app-header-line-color(@section-playlist-bg-color, @section-playlist-border-color, @section-playlist-hover-bg-color, @section-playlist-hover-border-color); }
      &.section-new { .app-header-line-color(@section-new-bg-color, @section-new-border-color, @section-new-hover-bg-color, @section-new-hover-border-color); }
      &.section-feedback { .app-header-line-color(@section-playlist-bg-color, @section-playlist-border-color, @section-playlist-hover-bg-color, @section-playlist-hover-border-color); }
    }
  }*/

  & {
    background: #b3b3b3 !important;

    /*.search {
      margin-top: 130px;
    }*/

    .app-header-line {
      margin-top: 5px;
      padding: 5px;
      padding-bottom: 0;

      background-color: #94288f;
    }

    .item {
      border-color: rgba(113, 0, 255, 0.5) !important;
      color: #00975E;
      background-color: #ffffff;
      text-shadow: rgb(229, 229, 229) 1px 1px 1px;

      &:active {
        background-color: #00975E !important;
        color: white;
        text-shadow: none;
      }
    }
  }
}