@import "../../../karaoke/common/media";
@import "../../../../branding/tcell/app-tcell-defenition";

.battle-page :global {

  @media @phone {
    color: @karaoke-tcell-white;
  }

  @media @desktop {
    color: @karaoke-text-color;
  }
}