.search-legacy() {

  //@karaoke-search-text-color: #ffffff;
  //@karaoke-search-placeholder-color: #eeeeee;
  //@karaoke-search-border-color: #ffffff;
  //@karaoke-search-button-active-color: rgba(17, 118, 238, 0.7);

  @karaoke-search-icon-size: 32px;

  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: @karaoke-search-border-color;

  input {
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0) !important;
    border: none !important;
    padding: 8px 12px;
    color: @karaoke-search-text-color;

    &:focus {
      outline: none;
    }
  }

  ::-webkit-input-placeholder { /* WebKit browsers */
    color: @karaoke-search-placeholder-color;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: @karaoke-search-placeholder-color;
    opacity: 1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: @karaoke-search-placeholder-color;
    opacity: 1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10+ */
    color: @karaoke-search-placeholder-color;
  }

  button {
    border: 0;
    background: transparent;
    position: absolute;
    top: 2px;
    right: 2px;
    padding: 0;

    display: block;
    width: @karaoke-search-icon-size;
    height: @karaoke-search-icon-size;
    //background-image: data-uri('./images/ico_search_shadow.png');
    background-size: @karaoke-search-icon-size @karaoke-search-icon-size;

    /*&:active {
      background-color: @karaoke-search-button-active-color;
    }*/
  }

  button[type="submit"] {
  }

  &.has-close {
    input {
      padding-left: 42px;
    }
    button[type="reset"] {
      left: 0;
      right: initial;
    }
  }
}

search {
  display: block;

  .search {
    .search-legacy();
  }
}

.search-impl(@offset-vertical, @offset-horizontal, @color-text, @color-placeholder, @icon-search) {
  .search-impl(@offset-vertical, @offset-horizontal, @color-text, @color-placeholder, @icon-search, 0);
}

.search-impl(@offset-vertical, @offset-horizontal, @color-text, @color-placeholder, @icon-search, @adjustment-right) {
  input {
    position: relative;
    
    @offset-right-with-image: @offset-horizontal + image-width(@icon-search) - @adjustment-right + 2px;
    padding: @offset-vertical @offset-right-with-image @offset-vertical @offset-horizontal;
    color: @color-text;
    
    &::placeholder {
      color: @color-placeholder;
    }
  }

  [type="submit"] {
    margin-right: @offset-horizontal;
    right: -1 * @adjustment-right;

    display: inline-block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    width: image-width(@icon-search);
    height: image-height(@icon-search);
    background-image: url(@icon-search);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &:hover {
      background-color: transparent;
      filter: brightness(1.2)
    }
  }
}