@import "../../../karaoke/common/media";
@import "../../../../branding/tcell/app-tcell-defenition";

.battle-status :global {

  .btn {
    .karaoke-tcell-button();
  }

  .text-muted{
    color: #c7c7c7;
  }

  @media @phone {
    color: @karaoke-tcell-white;
    input.text {
      color: @karaoke-text-color;
    }
  }

  @media @desktop {
    color: @karaoke-text-color;
  }
}