@import "../../../../branding/tcell/app-tcell-defenition";

.performance-response-selector :global {
  font-style: italic;

  .app-label {
    text-shadow: none;
  }

  .button {
    .hide-internal-text();
  }
}