button-row {

  @size: 42px;

  .icon {
    width: @size;
    height: @size;
    background-size: @size @size;
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    float: left;
    //margin: 2px 8px;
  }
}