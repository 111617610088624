@import "../../../common/media";

@phone-240: ~"only screen and (min-width: 240px) and (max-width: 320px)";
@phone-320: ~"only screen and (min-width: 320px) and (max-width: 480px)";
@phone-480: ~"only screen and (min-width: 480px)";

//picture-item {
//  //display: inline-block;
//
//  .catalog-item {
//    display: inline-block;
//  }
//
//  ng-transclude {
//    display: block;
//    display: none;
//  }
//}



@media @phone {
  .catalog-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0 2vw;

    text-align: center;
  }
}

/*@media @desktop {
  .catalog-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0 2vw;

    .catalog-item {
      width: 100%;
      .cover {
        width: 100%;
      }
    }
  }
}*/

/*@media @phone-480 {
  .catalog-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}*/

picture-item {
  display: inline-block;

  .catalog-item {
    margin: auto;
    text-align: center;
    //min-height: 180px;

    .cover {
      background-position: center center;
      min-height: 130px;
    }

    .album {
      display: block;
      //width: 120px;
      width: 100%;

      margin: -5px auto 10px;
      padding: 5px;

      /*background: black;
      color: white;
      text-decoration: underline;
      font-style: italic;
      &:hover {
        box-shadow: #1176ee 0 0 3em;
        text-decoration-color: #1176ee;
        -moz-text-decoration-color: #1176ee;
        -webkit-text-decoration: underline #1176ee;
        -webkit-text-decoration-color: #1176ee;
      }*/
    }
  }

  ng-transclude {
    display: block;
  }

}

.picture-item-ci(@size, @text-height) {
  .catalog-item {
    width: @size;

    .cover {
      width: @size;
      height: @size;
    }

    .album {
      min-height: @text-height;
    }
  }
}

@media @phone-240 { picture-item { .picture-item-ci(100px, 50px); } }
@media @phone-320 { picture-item { .picture-item-ci(130px, 50px); } }
@media @phone-480 { picture-item { width: 100%; .picture-item-ci(200px, unset); } }