@import "../media";

page-title {
  display: block;

  .button, .link-wrapper {
    a {
      border: 1px solid transparent;
      font-size: 14px;
    }
  }

  .submenu {
    overflow: hidden; // прячем элементы когда их становится много
    font-size: 14px;
    text-align: left;
    margin-left: 160px;
    margin-right: 160px;

    display: flex;
    flex-direction: row;
    align-content: center;

    & > * {
      margin-left: 16px;
      /*&:first-child {
        margin-left: 0;
      }*/
    }
  }

  .overlay-search {
    position:absolute; top: 0; left: 0; right: 0; bottom: 0;
    padding: 4px 18px;
    line-height: 0.8em;
    font-size: 1em;
    font-weight: normal;
    z-index: 2;

    search {
      background: white;
    }

    .result {
      display: none;
    }
  }

  @media @desktop {
    @offset: 126px;
    
    .overlay-search {
      padding: 4px 0;

      search {
        margin-left: @offset;
        form {
          border-bottom: 0;
        }
      }

      .search [type="submit"] {
        //display: none;
        right: 32px;
        top: 4px;
      }

      .result {
        //display: block;
        width: 100%;
        background-color: white;
        border: 1px solid lightgrey;
        display: flex;
        flex-direction: row;
      }

      .label {
        display: inline-block;
        width: @offset;
      }

      .grid-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: 0 2vw;
        flex-grow: 1;
      }

      .search.has-close button[type="reset"] {
        left: auto;
        right: 0;
      }
    }
  }

  .link-wrapper {
    width: 114px;
  }

  .submenu {
    font-size: 16px;
    margin-top: -36px;
    text-align: left;
    margin-left: 160px;

    //.tele2-font-main-regular();
    //font-color: @karaoke-t2w-color-gray;
    & > a {
      background-color: transparent;
    }
  }

  @media @desktop {
    .app-header .button {
      &.menu-button {
        display: none;
      }
      &.back-button {
        display: none;
      }
    }

    .button {
      float: right;
      right: auto;
      .text {
        font-size: 11.2px;
      }
    }

    .link-wrapper {
      order: 1;
    }
    .submenu {
      order: 2;
      flex-grow: 1;
    }
    .profile-button {
      order: 3;
    }
    .auth-button {
      order: 3;
    }
    .app-header .button.auth-button .text {
      margin-top: 4px;
      max-width: 50px;
    }
    .lang-button {
      order: 4;
    }
    .search-button {
      order: 5;
    }

    .app-header {
      display: flex;
      flex-direction: row;

      .submenu, .button {
        margin: 0;
        right: auto;
        left: auto;
        top: auto;
        position: unset;
      }

      .button {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        place-self: center;
        .text {
          overflow: hidden;
        }
      }

      .submenu > a, .button .text {
        display: inline-block;
        //margin-top: 16px;
        align-self: center;
        white-space: initial;
        text-align: left;
        line-height: 1;
      }
      .button {
        a {
          width: auto;
          background-position: left center;
        }
        .text {
          margin-left: 28px;
          margin-top: 10px;
        }
      }
    }
  }
  
  @media @phone {
    .submenu {
      display: none;
    }

    .button {
      .text {
        display: none;
      }
    }
  }
}

// language patch
body.lang-uz {
  page-title {
    .app-header .button.auth-button .text {
      max-width: 68px;
    }
    .logo:after {
      content: "KARAOKE";
    }
  }

  menu {
    .app-header .logo:before {
      content: "KARAOKE";
    }
  }
}

