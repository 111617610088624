@import "../../../karaoke/common/media";

.battle-page {
  .row:after {
    content: '';
    display: block;
    clear: both;
  }

  .song-name {
    font-weight: bold;
  }

  .action {
    &.btn {
      margin: 5px;
    }
  }

  @media @phone {
    .time, .title, .status {
      margin-left: 16px; // todo
    }
  }

  @media @desktop {

    .time, .title {
      //float: left;
      display: inline-block;
    }

    .title {
      margin: 10px;
    }

    .status {
      float: right;
    }
  }
}