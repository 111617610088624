.battle-status {

  .jumbotron {
    margin-bottom: 8px;
  }

  .error {
    color: red;
  }

  .buttons-row {
    margin-top: 6px;
    margin-bottom: 6px;

    .btn {
      margin-top: 6px;
      margin-left: 6px;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
}