@import "../../../../branding/app-defenitions";

.karplayer-impl() {

  #passage-text {
    -webkit-transform: scale(1);
  }

  .kar-text {
    line-height: 1.2em;
  }

  .kar-progress {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 80px;

    .progress {
      margin-bottom: 5px;
      height: 4px;
      border-radius: 10px;
    }
  }

  .wrapper {
    font-size: 14px;
  }

  .control-block {
    background: transparent;
  }

  .player-song-copyright > * {
    padding-top: 2.4em;
    font-size: 11.2px;
    line-height: 1.2em;
    //font-style: italic;
    p {
      margin: 0 0 4px;
    }
  }

  .control-block.mobile {
    position: relative;

    &.media {
      padding-top: 10px;
      .img-circle {
        border-radius: 0;
      }
    }

    .category {
      margin-left: 74px;
      margin-bottom: 0;
      .icon {
        display: none;
      }
    }

    .cover {
      border-radius: 0;
      width: 64px;
      height: 64px;
      margin-top: -18px;
    }

    .artist {
      display: inline-block;

      &:after {
        content: ' – ';
        display: inline-block;

        margin-left: 0.2em;
      }
    }

    .kar-button-main .btn.btn-primary {
      border: none;
      &:hover {
        //background-color: rgba(255, 255, 255, 0.2);
        background-color: transparent;
      }
    }

    .kar-button-image() {
      background-size: contain;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      background-repeat: no-repeat;
    }

    .kar-button-main {
      float: none;
      margin-top: -6px;
      margin-left: auto;
      margin-right: auto;

      max-width: 24%;

      .btn.btn-primary {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
      }

      .stop, .pause, .play, .load, .play-next {
        .kar-button-image();
      }
    }

    .play-next.btn, .play-next.btn:hover {
      float: none;
      margin-top: -6px;

      max-width: 24%;
      position: absolute;
      bottom: 10px;
      border: 0;
      right: 32px;

      background: transparent;

      span {
        display: none;
      }

      .icon {
        display: none;
      }
    }
  }



  .control-block.desktop {
    margin: 0;
    padding: 0;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    player-song-title {
      position: absolute;
    }

    .media-body {
      .artist {
        margin-top: 10px;
        font-weight: bold;
        line-height: 1.5em;
      }
      .song {
        font-weight: bold;
        line-height: 1.5em;
      }
    }

    .control-block.media {
      margin: 0;
      padding: 0;
    }

    .control-block .cover {
      border-radius: 0;
      width: 64px;
      height: 64px;
    }

    .play-pause {
      width: 54px;
      margin: 5px;
    }

    .record {
      margin-top: 0;
      margin-left: 0;
      float: right;
    }

    .play-end, .play-next {
      float: right;
      padding: 0;
      margin: 0;

      .pb-label {
        display: none;
      }
    }

    .kar-progress {
      bottom: -10px;
      .progress {
        margin: 0;
        height: 10px;
        border-radius: 0;
      }
    }
  }

  @media @desktop {
    .siri-container {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      //bottom: 0;
      bottom: 20%;
    }

    .wrapper {
      position: relative;
      padding: 0 @desktop-offset;
    }

    /*.wrapper > * {
      position: absolute; top:0; right: 0; bottom: 0; left: 0;
    }*/

    .wrapper:after {
      content: '';
      clear: both;
    }

    player-song-copyrights {
      display: inline-block;
      margin-top: 28px;
      margin-left: 18px;
    }
  }

  player-button {
    .pb-label {
      font-weight: bold;
      font-style: normal;
      font-size: 0.75em;
      text-align: center;
    }
  }

  player-button.record {
    float: left;
    margin-top: -68px;
    margin-left: 32px;

    .pb-label {
      display: none;
    }
  }

  button-row.record-mode {
    .icon {
      width: 42px;
      height: 42px;
      display: block;
      float: left;
      margin-left: 10px;
      margin-right: 10px;
      background-size: 100%;
    }
  }

  .kar-loader {
    //display: none;
    display: block;
    background-color: rgba(0, 0, 0, 0.85);

    z-index: -1; // для доступности заголовка
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    opacity: 0;
    .screen {
      color: white;

      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      text-align: center;

      transform: translateY(-50%) scale(1, 1);
      opacity: 0;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-direction: normal;
      animation-fill-mode: forwards;
      animation-play-state: running;

      &.screen1 {
        animation-delay: 0s;
      }
      &.screen2 {
        animation-delay: 1s;
      }
      &.screen3 {
        animation-delay: 2s;
      }
    }
  }

  //

  .kar-progress {
    .progress {
      position: relative;

      .progress-bar {
        .transition(width 0.05s linear);
      }
    }

    .rewinder {
      display: none;
      background: black;
    }

    &.rewinderable {
      .progress {
        cursor: pointer;
        overflow: hidden;
      }

      .rewinder {
        display: block;
        position: absolute;
        width: 3px;
        top: 0;
        bottom: 0;
        height: 100%;
        z-index: 1;
        left: -3px;
      }
    }
  }

}