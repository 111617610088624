@import "../../../../application/style/mixins.less";

// корневой переключатель
html.menuOpen &,
body.menuOpen & {
  #main {
    position: fixed;
    & > * {
      pointer-events: none; // когда меню открыто, его элементы некликабельны
    }
  }
}

// цвета
.app-header-line-color(@bg, @border, @hbg, @hborder) {
  background-color: @bg;
  border-color: @border;

  &:hover, &:active {
    background-color: @hbg;
    border-color: @hborder;
  }

  &:before {
    background-color: @bg;
  }

  .text:before {
    background-color: @bg;
  }
}

.app-header-line-menu-color(@bg, @border, @hbg, @hborder) {
  background-color: transparent;
  border-color: @border;

  &:hover, &:active {
    background-color: @hbg;
    border-color: @hborder;
  }
}

menu {
  // ovveride chrome defaults for menu
  margin-block-start: 0;
  margin-block-end: 0;

  .app-header-line {

    background-color: transparent;
    padding: 10px;

    .text {
      font-size: 1.4em;
      margin: 6px 0 6px 6px;
      padding: 0;
    }

    border: 0;
    border-bottom: 1px solid;

    .open, .close {
      display: block;
      width: 46px;
      height: 42px;

      margin-top: -5px;
      margin-right: -5px;

      background-size: 32px 32px;
    }
  }

  .item {
    padding-left: 40px;
    border-bottom: 1px solid rgba(0, 145, 255, .5);
    color: #fff;

    span {
      margin: 10px 20px;
    }
  }

  &:active {
    background-color: rgba(0, 145, 255, 0.7);
  }

  a {
    color: #ffffff;
  }

  // содержимое всплывающего меню
  #menu {

    .menuTransition() {
      -webkit-transition: 0.2s linear;
      -moz-transition: 0.2s linear;
      -o-transition: 0.2s linear;
      transition: 0.2s linear;
    }

    .fullscreen();
    z-index: 10;

    left: -100%;

    .app-header {
      .back-button a {
        //content: data-uri('./images/ico_close.png');
        //.image-content('./images/ico_close.png');
      }
    }

    .item {
      font-size: 1.1em;
      display: block;

      span {
        display: inline-block;
        //margin: 10px 20px;
      }

      a:hover, a:active {
        text-decoration: none;
      }
    }

    html.menuOpen &,
    body.menuOpen &
    {
      left: 0;
      overflow-y: auto;
    }

    .menuTransition();
  }
}
