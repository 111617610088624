@import "../../../../branding/tcell/app-tcell-defenition";
@import "SearchComponent";

@icon-search: './tcell/search.png';
@offset-horizontal: 16px;
@offset-vertical: 18px;
@color-text: @karaoke-search-text-color;
@color-placeholder: @karaoke-search-placeholder-color;

.search :global {
  background: @karaoke-tcell-dark-grey;
  border: 1px solid @karaoke-tcell-light-black;

  .search-impl(@offset-vertical, @offset-horizontal, @color-text, @color-placeholder, @icon-search, 0px);
}

