@import "../media";

.share-social-button-images(@prefix: "./icons/", @suffix: "") {

  .icon(@directory, @name, @suffix, @ext) {
    background-image: url("@{directory}@{name}@{suffix}@{ext}");
  }

  .impl(@prefix, @prefix, @ext) {
    & { // player-button
      &.vk .pb-icon { .icon(@prefix, "vk", @suffix, @ext); }
      &.fb .pb-icon { .icon(@prefix, "fb", @suffix, @ext); }
      &.ok .pb-icon { .icon(@prefix, "ok", @suffix, @ext); }
    }
  }

  @media @regular { .impl(@prefix, @suffix, ".png"); }
  @media @retina { .impl(@prefix, @suffix, "@2x.png"); }
}

.share-social-button-images-hovered(@prefix) {
  .share-social-button-images(@prefix, "_off");
  &:hover { .share-social-button-images(@prefix, "_on"); }
}

.share-social {
  player-button {

    .share-social-button-images();

    &:first-child {
      margin-left: 0;
    }

    width: 32px;
    margin-left: 12px;

    .pb-label {
      display: none;
    }
  }
}
