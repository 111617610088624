@import "../../../../branding/app-defenitions";

.performance-response-selector {

  .app-label {
    background-color: transparent;
    float: left;
  }

  .button-block {
    float: right;
  }

  & > *:after {
    content: "";
    display: block;
    clear: both;
  }

  padding: @karaoke-dim-page-padding-mobile;

  @media @desktop {
    padding: @karaoke-dim-page-padding-mobile @karaoke-dim-ident-page;
  }
}