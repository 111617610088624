//noinspection CssInvalidHtmlTagReference
player-volume {
  display: inline-block;
  width: 180px;
  height: 60px;
  margin: 6px 0;

  .vol {
    display: inline-block;
    
    width: 12px;
    height: 10px;
    background: lightgray;
    margin-right: 6px;
    cursor: pointer;

    &.active {
      background: black;
    }
  }
}

.icon-volume-user {
  background-image: url("../icons/ico_volume_user@2x.png");
  display: inline-block;
  background-size: 60px 60px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 60px;
  height: 60px;
}

.icon-volume-phone {
  background-image: url("../icons/ico_volume_phone@2x.png");
  display: inline-block;
  background-size: 60px 60px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 60px;
  height: 60px;
}