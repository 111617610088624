@import "../../../../branding/tcell/app-tcell-defenition";

.player-song-progress :global {
  .progress {
    background: @karaoke-tcell-color-violet-dark;
    .progress-bar {
      background-color: @karaoke-tcell-color-white;
    }
  }

  .progress-begin, .progress-end {
    .karaoke-tcell-font-small();
    color: @karaoke-tcell-color-white;
  }
}