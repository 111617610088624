player-button {
  vertical-align: top;
  display: inline-block;
  width: 68px;
  
  & > a {
    display: inline-block;
    position: relative;
    width: 100%; // 50%;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    & > .pb-icon {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .pb-icon {
    /*border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;*/
    background-position: center;
    background-size: 100%;
  }

  .pb-label {
    display: inline-block;
    text-align: center;

    background-size: contain;
    background-repeat: no-repeat;

    &:empty {
      display: none;
    }
  }
}